nav {
    background-color: var(--nav);
    height: 6vh;  
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    color: rgb(225, 220, 220);
    padding-top: 0.2em;  
   
} 

.nav-links {
    font-size: 2em;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    cursor: pointer;
}

.nav-links:hover {
    text-decoration: underline 1.5px;
}

/* #socials {
    display: grid;
} */

/* #drop-down {
    background-color: var(--nav);
    display: none;
    position: absolute;
    margin-top: 5vh;
}

#socials:hover #drop-down {
    display: flex;
}

#drop-down a#instagram-text {
    text-decoration: none;
    color:rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding: 1em 0em 0em 1em;
    font-weight: 600;
} */

.nav-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    width: 100%;
    background-color: var(--background);
    z-index: 10;
    position: fixed;
    transition: all 0.25s ease-in-out;
}

#featureflorals {
    text-transform: uppercase;
    font-family: 'Cormorant', serif;
}


#featureflorals:hover {
    text-decoration: none;
}

@media only screen and (max-width: 550px) {
    .nav-links {
        font-size: 1.5em;
        opacity: 0;
    }

    nav {
        position: fixed;
        width: 100%;
        z-index: 10;
        /* opacity: 0.8; */
    }

    .visible {
        opacity:1
    }

    .inactive {
        width: 11.5%;
        right: 0;
        opacity: 0.9;
    }

    #featureflorals {
        opacity: 1;
        /* outline: solid black 1px; */
        width: 100vw;
        padding: 0.5em 1em;
        position: absolute;
        /* left: -80vw; */
        right: 0vw;
        top: 0vh;
        color: black;
        font-weight: 600;
        background-color: var(--background);
    }
}
