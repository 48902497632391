.gallery-grid {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    padding: 1em;
    margin: 3em 2em 0em 2em;
}

  .gal-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

 

  @media only screen and (max-width: 550px) {
    .gallery-grid {
      grid-template-columns: repeat( auto-fit, minmax(40vw, 1fr) );
    } 
    .span-2 {
    grid-row: 1, 3;
    }
  }
