h1.checkout {
    padding: 1em 1em 1em 1em;

}

.basket-info {
    display: grid;
    place-items: center;

}

.basket-info p {
    text-decoration: none;
    color: black;
}

.checkout-btn {
    padding: 1.5em 3em;
    border-radius: 5px;
    border: none;
    background-color: var(--background);
    color: white;
    background-color: green;
    text-align: center;
    cursor: pointer;
    margin: 1em;
}

.checkout-btn:hover {
    background-color: orange;
}

.btn-div {
    display: flex;
    justify-content: center;
}

.center-basket {
    display: grid;
    text-align: left;
    place-items: center;
    background-color: linen;
    font-weight: 600;
    margin-bottom: 2em;
    padding: 1em;
}

.postcodes {
    padding: 1em;
    font-weight: 600;
    display: grid;
    justify-content: center;
    outline: 3px solid linen;
}

.postcodes p {
    margin: 0.5em;
}

input#postcode {
    width: 5em;
    padding: 0.1em;
    margin: 0.5em;
}

.center-basket p {
    padding: 1em;
}

.pc-modal {
    outline: 1px solid black;
}
