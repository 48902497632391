@media only screen and (max-width: 550px) {
    #insta-btn {
        margin: 0em 2em;
        padding: 1em 2em;
    }
   
    .button-container {
        padding: 0.1em 0em;
        /* height: 5em; */
        /* margin: 1em; */
        /* height: 5em; */
    }
}

