.about {
    display: flex;
    margin: 2em 3em;
    height: 70vh;
    gap: 3em;
}

.center-text {
    text-align: center;
}

.about-ps {
    padding: 1em 2em;
    background-color: var(--bg-text);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.img-container {
    width: 100%;
    height: 100%;
} 

img.about-img1, img.about-img2, img.about-img3 {
    width: 100%;
    object-fit: contain;
}

.about-ps p{
    font-size: 1em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.6;
}

.cursive-font {
    font-family: 'Italianno', cursive;
    font-size: 3em;
    font-weight: 200;
    color:rgba(0, 0, 0, 0.7);
}

p#no-pad {
    padding-bottom: 0em;
}

@media only screen and (max-width: 700px) {

    .img-container {
        margin: 0.5em 0em;
    }

    h1.about-h1 {
        font-size: 4em;
        padding-top: 1.3em;
        margin-bottom: 0em;
    }

    .about {
        display: grid;
        margin: 1.5em;
        /* outline: solid 1px black; */
    }

    .cursive-font {
        font-size: 2em;
        padding: 0em 0.25em;
    }

    .about-ps {
        padding: 1em 2em;
        /* text-align: left;
        outline: 1px solid black; */
    }

    .about-ps p {
        margin-bottom: 0.5em;
    }

    .insta-container {
        position: relative;
        outline: 1px solid black;
        height: 10vh;
    }
}

