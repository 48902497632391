img.visible {
    opacity: 1;
}

.mix1 {
    background: url('../../../public/images/mobileProducts/mixed-bouquet-side.webp');
}

.mix2 {
    background: url('../../../public/images/mobileProducts/mixed-bouquet.webp');
}

.mix3 {
    /* background: url('../../../public/images/mobileProducts/seasonal-bouquet-front.webp'); */
}

.mix4 {
    background: url('../../../public/images/mobileProducts/seasonal-bouquet.webp');
}

.mix1, .mix2, .mix3, .mix4 {
    position: absolute;
    width: 100%;
    height: 40vh;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.7s ease-in-out;
}

div.active-pic {
    opacity: 1;
}

@media only screen and (min-width: 1000px) {

    

    .mix1 {
        background: url('../../../public/images/dsk-prods/mixed-bouquet.webp') center center;
    }
    
    .mix2 {
        background: url('../../../public/images/dsk-prods/mixed-front.webp') center center;
    }
    
    .mix3 {
        background: url('../../../public/images/dsk-prods/mixed-shadow.webp') center center;
    }
    
    .mix4 {
        background: url('../../../public/images/dsk-prods/mixed-side.webp') center center;
    }

    .mix1, .mix2, .mix3, .mix4 {
        height: 100%;
        background-repeat: no-repeat;
        width: 100%;
        background-size: contain;
    }
    .mixed-image-container {
        height: 60vh;
    }
}
