.contact-grid {
    display: grid;
    grid-template-columns:1fr 1fr;
    padding: 1em;
    gap: 4em;
    justify-content: center;
    height: 60vh;
    margin: 0em 4em;
}

.contact-grid > div {
    width: 100%;
    height: 40vh;
}


.contact-grid > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 30%;
}

.contact-ps {
    background-color: rgba(245, 202, 195, 0.3);
    /* padding: 2em; */
    display: flex;
   flex-direction: column;
   justify-content: center;
    
}

.contact-ps p {
    font-size: 1em;
    padding: 1em 2em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.6;
}

#contact-anchor {
    text-align: center;
    margin: 0em;
    padding-bottom: 1em;
    color: rgba(0, 0, 0, 0.7);
    font-size: 3em;
    font-family: 'Italiana', sans-serif;
    font-weight: 400;
    letter-spacing: 2px;
}

@media only screen and (max-width: 550px) {
    .contact-grid {
        grid-template-columns: 1fr;
        gap: 0px;
        margin: 0em 0em 1em 0em;
    }
}
