.button-container {
    /* background-color: var(--background); */
    background-color: white;
    width: 100%;
    height: 9em;
    display: grid;
    place-items: center;
}

.button-container button {
    padding: 1em 4em;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 1.2em;
    font-family: 'Italiana', serif;
    font-weight: 600;
    background-color: var(--background);
    color: rgba(0, 0, 0, 0.7);
    border: solid 1px rgba(0, 0, 0, 0.7);
}

.button-container button:hover {
    background-color: #e3d5ca;
}
