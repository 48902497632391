.cart-product {
    display: flex;
    text-align: left;
}
.cart-item {
    display: grid;
}

button.remove-btn {
    cursor: pointer;
    background-color: red;
    border-radius: 5px;
    width: 5em;
    border: none;
    outline: none;
    height: 2.5em;
    margin: 4em;
    color: white;
}

button.remove-btn:hover {
    background-color: orange;
}
