form {
    display: grid;
    justify-content: center;
    gap: 3px;
    caret-color: black;
}

.success, .error {
    text-align: center;
    font-size: 1.5em;
    padding-bottom: 1em;
    font-weight: 600;
}

#message {
    resize: vertical;
    background-color: rgb(255, 245, 245);
    border: 1px solid rgb(195, 192, 192);
    border: 1px solid var(--background);
    padding: 0.5em
}

form input {
    height: 3em; 
    background-color: rgb(255, 245, 245);
    border: 1px solid rgb(195, 192, 192);
    border: 1px solid var(--background);
    padding-left: 0.5em;
}

form label {
    font-weight: 600;
}

.form-submit {
    font-family: 'Italiana', serif;
    font-weight: 600;
    margin: 1em 0em;
    border: 1px solid var(--background);
}

.form-submit:hover {
    background-color: var(--background);
}

@media only screen and (max-width: 760px) {
    form input, #message {
        
    }
}
